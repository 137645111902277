<template>
  <div class="chat-input" @click.stop>
    <div v-if="isAllMute" class="all-mute">
      全体禁言已开启，再次点击图标取消禁言
    </div>
    <div class="function">
      <div
        class="emoji"
        :class="{ active: showEmoji }"
        @click="toggleEmoji"
      ></div>
      <div class="upload-img">
        <input
          id="uploadCourseImg"
          class="uploadCourseImg"
          type="file"
          accept="image/*"
          @change="$emit('upload-course-img', $event)"
        />
      </div>
      <div class="muted" @click="allMute" :class="{ disable: isAllMute }"></div>
    </div>
    <div class="send-msg">
      <textarea
        v-model.trim="msg"
        class="textarea"
        placeholder="输入内容"
        @keyup.enter="sendMsg"
        maxlength="255"
        v-focus
      ></textarea>
      <div
        class="send-btn"
        :style="`opacity: ${msg ? 1 : 0.3}`"
        @click="sendMsg"
      >
        发送
      </div>
    </div>
    <!--表情-->
    <template v-if="showEmoji">
      <el-carousel height="175px" :autoplay="false">
        <el-carousel-item class="swiper-slide">
          <ul class="emojis">
            <li
              class="emoji"
              @click.stop="addEmoji(key)"
              :class="{ isShow: index <= 20 }"
              v-for="(value, key, index) in emojiSource"
              :key="index"
            >
              <div :title="key" class="emoji-bg" :style="value.value"></div>
            </li>
          </ul>
        </el-carousel-item>
        <el-carousel-item class="swiper-slide">
          <ul class="emojis">
            <li
              class="emoji"
              @click.stop="addEmoji(key)"
              :class="{ isShow: index > 20 && index <= 41 }"
              v-for="(value, key, index) in emojiSource"
              :key="index"
            >
              <div :title="key" class="emoji-bg" :style="value.value"></div>
            </li>
          </ul>
        </el-carousel-item>
        <el-carousel-item class="swiper-slide">
          <ul class="emojis">
            <li
              class="emoji"
              @click.stop="addEmoji(key)"
              :class="{ isShow: index > 41 }"
              v-for="(value, key, index) in emojiSource"
              :key="index"
            >
              <div :title="key" class="emoji-bg" :style="value.value"></div>
            </li>
          </ul>
        </el-carousel-item>
      </el-carousel>
    </template>
  </div>
</template>
<script>
import emoji from './emoji'

export default {
  name: 'chatInput',

  props: {
    showEmoji: Boolean,
    isAllMute: Boolean,
  },

  data() {
    return {
      msg: '',
      emojiSource: emoji,
    }
  },

  methods: {
    sendMsg() {
      this.$emit('sendMessage', { value: this.msg.slice(0, 255) })
      this.msg = ''
    },

    addEmoji(emoji) {
      this.msg += emoji
    },

    toggleEmoji() {
      this.$emit('updateEmojiStatus', !this.showEmoji)
    },

    // 全体禁言
    allMute() {
      this.$emit('updateGroupMute')
    },
  },
}
</script>
<style lang="scss" scoped>
.chat-input {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  background: #fff;
  .function {
    display: flex;
    align-items: center;
    .emoji {
      width: 46px;
      height: 36px;
      background: url('~@ass/img/1.3.6/icon_bq.png') no-repeat;
      background-size: 16px;
      background-position: 20px center;
      cursor: pointer;
      &.active {
        background: url('~@ass/img/1.3.6/icon_bq_xz.png') no-repeat;
        background-size: 16px;
        background-position: 20px center;
      }
    }
    .upload-img {
      width: 46px;
      height: 36px;
      position: relative;
      background: url('~@ass/img/1.3.6/icon_tp2.png') no-repeat;
      background-size: 16px 14px;
      background-position: 20px center;
      cursor: pointer;
    }
    .muted {
      width: 46px;
      height: 36px;
      background: url('~@ass/img/1.3.6/icon_jy.png') no-repeat;
      background-size: 16px;
      background-position: 20px center;
      cursor: pointer;
      &.disable {
        background: url('~@ass/img/1.3.6/icon_jy_kq.png') no-repeat;
        background-size: 16px;
        background-position: 20px center;
      }
    }
  }
  .send-msg {
    position: relative;
    .textarea {
      display: block;
      width: 100%;
      height: 84px;
      border: 0;
      resize: none;
      font-size: 12px;
      color: #333333;
      line-height: 12px;
      padding: 0 20px;
      box-sizing: border-box;
    }
    .send-btn {
      position: absolute;
      right: 10px;
      bottom: 10px;
      min-width: 48px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      background: #1b9d97;
      border-radius: 4px;
      font-size: 12px;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }
  }
  ::v-deep .el-carousel__button {
    background: #666;
  }
}
/****表情 */
.swiper-slide {
  background: #fff;
  display: inline-block;
  .emojis {
    display: flex;
    flex-wrap: wrap;
    .emoji {
      display: none;
      width: 14.28%;
      background-color: white;
      .emoji-bg {
        width: 25px;
        height: 25px;
        cursor: pointer;
        margin: 14px auto;
        background: url(https://img.dingdingkaike.com/minApp/emoji3x.png?imageslim)
          no-repeat;
        background-size: 175px 175px;
      }
    }
    .isShow {
      display: block;
    }
  }
}
.uploadCourseImg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.all-mute {
  height: 12px;
  font-size: 12px;
  color: #ff3535;
  line-height: 22px;
  height: 22px;
  padding-left: 20px;
  background: #ffeded;
}
</style>
