<template>
  <div class="member-contain">
    <!-- <div class="member-options">
    <div
      v-for="(item, index) in types"
      class="vm text"
      :class="{ selected: selected === index }"
      @click="toggleMemberList(index)"
      :key="item"
    >
      {{ item }}
    </div>
  </div> -->
    <div class="search">
      <el-input v-model="search"
                clearable
                placeholder="输入昵称搜索"></el-input>
    </div>
    <!--成员列表-->
    <ul class="member-ul">
      <li v-for="item in currListData" :key="item.userID">
        <el-popover popper-class="char-member-popover"
                    placement="bottom-start"
                    :offset="59"
                    width="147"
                    trigger="click"
                    :disabled=" item.role != 'Member'||type!=0">
          <div class="member-limit" v-if="selected == 0">
            <div class="btn1"
                 @click="
                toggleConfirm(item, 'confirm2Data', 'markChatroomGaglist')
              ">
              禁言
            </div>
            <div class="btn3"
                 @click="
                toggleConfirm(item, 'confirm3Data', 'markChatroomBlacklist')
              ">
              拉黑
            </div>
          </div>
          <div class="member-item" slot="reference">
            <!--禁言 踢出 拉黑-->
            <img class="img" :src="item.avatar" />
            <div class="chat-tag blue member-tag-margin"
                 v-if="item.role === 'Owner'">
              老师
            </div>
            <div class="chat-tag orange member-tag-margin"
                 v-else-if="item.role === 'Admin'">
              助教
            </div>
            <div class="name">{{ item.nick }}</div>
            <div @click.stop="editRestricteUser(item)"
                 v-if="selected > 0"
                 class="cancel-btn">
              取消{{ selected === 1 ? '禁言' : '拉黑' }}
            </div>
          </div>
        </el-popover>
      </li>
    </ul>
    <div v-if="currListData.length === 0" class="empty">
      <img class="img" src="~@ass/img/1.3.6/icon_kcyjs.png" alt="" />
      <div class="prmopt">暂无数据</div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'member',

    props: {
      link: Object,
      member: Object,
      type: Number
    },

    data() {
      return {
        deBug: true,
        search: '',
        selected: this.type,
        memberItemInfo: {},
        // 群成员
        currListData: [],
        confirm2Data: {
          title: '禁言提示',
          content:
            '禁言后，该学员不能在直播间发言，可在【已禁言】模块取消禁言。'
        },
        confirm3Data: {
          title: '拉黑提示',
          content:
            '拉入黑名单后，学员访问网校将提示被拉黑，不可购课、看课和进入直播间。你可以在【已拉黑】模块移出黑名单；机构管理员可在【学生管理】中操作。'
        },

        // 用户订单信息
        userOrderInfo: {
          count: 0,
          price: 0
        }
      }
    },

    computed: {
      types() {
        const value = ['在线', '已禁言', '已拉黑']
        return value
      }
    },
    watch: {
      member: {
        handler(item) {
          this.setMemberList(item)
        },
        deep: true,
      },
      search: {
        handler() {
          this.setMemberList(this.member)
        },
      },
    },
    methods: {
      setMemberList(item) {
        let result = []
        const member = item
        for (const i in member) {
          if (i !== 'length' && i !== 'onlineLen') {
            let flag
            const item = member[i]
            switch (this.selected) {
              case 0:
                flag = item.muteUntil * 1000 <= Date.now() && item.disabled!=true
                break
              case 1:
                flag = item.muteUntil * 1000 > Date.now() && item.disabled != true
                break
              case 2:
                flag = item.disabled
                break
            }

            // 搜索功能
            if (this.search && !new RegExp(this.search, 'i').test(item.nick)) {
              flag = false
            }

            if (flag) {
              result.push({ ...item })
            }
          }
        }
        this.deBug && console.log('群成员更新', member, result);
        this.$emit('length', result.length)
        result = result.sort((a, b) => b.sort - a.sort)
        this.currListData = result
      },
      // 设置当前操作的成员
      editCurrMemberValue(value) {
        this.memberItemInfo = value || {}
      },

      // 编辑受限用户
      editRestricteUser(value) {
        this.editCurrMemberValue(value)
        switch (this.selected) {
          case 1:
            this.markChatroomGaglist()
            break
          default:
            this.markChatroomBlacklist()
        }
      },

      // 操作弹窗
      toggleConfirm(item, cName, fn) {
        // 如果是客户端 且存储空间达到上限 大班课就提示空间已满
        this.$confirm(this[cName].content, this[cName].title, {
          confirmButtonText: '确定'
        }).then(() => {
          this.editCurrMemberValue(item)
          this[fn]()
        })
      },

      // 禁言
      markChatroomGaglist() {
        this.$emit('updateMemberMute', this.memberItemInfo)
      },

      // 拉黑
      markChatroomBlacklist() {
        this.$http({
          url: '/Assistant/blacklist',
          data: {
            userID: this.memberItemInfo.userID,
            correlation_id: this.$route.query.correlation_id,
            ustatus: this.memberItemInfo.disabled ? 1 : 2
          },
          callback: ({ code }) => {
            if (code === 200) {
              this.$root.prompt({
                msg: '操作成功',
                type: 'success'
              })
            }
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .member-contain {
    flex: 1;
    height: 100%;
    position: relative;
  }

  .member-ul {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-top: 10px;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
  }

  .member-options {
    background-color: #f5f5f5;

    .text {
      color: #999999;
      cursor: pointer;
      font-size: 0.65rem;
      font-weight: 500;
      line-height: 0.9rem;
      padding: 0.75rem 0.65rem 0.5rem;

      &.selected {
        color: #0aa29b;
      }
    }
  }
  /**成员列表**/
  .member-tag-margin {
    margin-right: 0.3rem;
  }

  .member-item {
    display: flex;
    padding: 10px 0;
    cursor: pointer;
    align-items: center;
    background-color: #fff;

    &:hover {
      background: #ededed;
    }

    .img {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      margin: 0 0.5rem 0 0.7rem;
    }

    .name {
      flex: 1;
      color: #333;
      font-size: 14px;
      font-weight: 500;
      line-height: 0.9rem;
    }

    .icon1 {
      width: 0.4rem;
      height: 0.6rem;
      margin-right: 0.7rem;
    }

    .icon2 {
      width: 0.9rem;
      height: 0.9rem;
      margin-right: 0.7rem;
      filter: grayscale(100%);

      &.selected {
        filter: none;
      }
    }
  }

  .cancel-btn {
    color: #0aa29b;
    cursor: pointer;
    font-size: 0.6rem;
    font-weight: 400;
    padding: 0 0.7rem;
    line-height: 2.1rem;
  }

  .edit {
    width: 2.2rem;
    height: 2.1rem;
    cursor: pointer;
    background: url('https://img.dingdingkaike.com/minApp/right-jt-gray.png') no-repeat center center;
    background-size: 8px 12px;
  }
  /**成员详细操作 拉黑 踢出等等**/
  .member-detail {
    background-color: #fff;
  }

  .member-detail-info {
    display: flex;
    align-items: center;
    padding: 1.5rem 0 2.7rem;
  }

  .member-detail-img {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.2rem;
    margin: 0 0.75rem 0 0.85rem;
  }

  .member-detail-right {
    flex: 1;

    .t1 {
      color: #333;
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1.05rem;
      word-break: break-all;
    }

    .t2 {
      font-size: 0.55rem;
      font-weight: 400;
      margin-top: 0.2rem;
      line-height: 0.8rem;
      color: rgba(153, 153, 153, 1);
    }
  }

  .member-detail-btn {
    font-size: 0;
    display: flex;

    %text {
      color: #7b7b7b;
      font-size: 0.65rem;
      font-weight: 500;
      line-height: 0.9rem;
      margin-left: 0.5rem;
    }

    .t1,
    .t2,
    .t3 {
      flex: 1;
      font-size: 0;
      padding: 1.2rem 0;
      text-align: center;

      img {
        width: 0.9rem;
        height: 0.9rem;
      }

      div {
        @extend %text;
      }
    }

    .t1 {
      img {
        width: 0.9rem;
        height: 1rem;
      }
    }

    .t3 {
      div {
        color: #ff3535;
      }
    }
  }

  .chat-tag {
    padding: 2px 4px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    min-width: 1.15rem;
    text-align: center;
    border-radius: 0.1rem;

    &.blue {
      background-color: #388bde;
    }

    &.orange {
      background-color: #f5812e;
    }

    &.green {
      background-color: #0aa29b;
    }
  }

  .empty {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .img {
      display: block;
      margin: 0 auto;
    }

    .prmopt {
      font-size: 12px;
      color: #666666;
      line-height: 12px;
      margin-top: 20px;
      text-align: center;
    }
  }

  ::v-deep .search {
    background: #f5f5f5;
    padding: 20px;

    .el-input__inner {
      border: 0;
      font-size: 13px;
    }
  }
</style>
<style lang="scss">
  .char-member-popover {
    margin-top: -10px !important;
  }

  .member-limit .btn1,
  .member-limit .btn2,
  .member-limit .btn3 {
    font-size: 14px;
    color: #333333;
    line-height: 14px;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background: #ededed;
    }
  }

  .member-limit .btn3 {
    color: #ff3535;
  }
</style>
