import { from, interval } from 'rxjs'
import { bufferTime, map } from 'rxjs/operators'
import TencentCloudChat from '@tencentcloud/chat';
import { mockJson, mockJson2 } from './mock'
import emoji from '../emoji'

export const deBug = true

// 登录
export const login = (chat, userID, userSig) => {
  return new Promise((res, rej) => {
    chat.login({
      userID,
      userSig,
    }).then((imResponse) => {
      deBug && console.log('登录', imResponse);
      res(imResponse)
    }).catch(err => {
      console.warn('getGroupMemberList error:', err);
      rej(err)
    })
  })
}

// 加群
export const joinGroup = (chat, groupID) => {
  return new Promise((res, rej) => {
    chat.joinGroup({
      groupID,
      type: TencentCloudChat.TYPES.GRP_MEETING,
    }).then((imResponse) => {
      deBug && console.log('加群', imResponse);
      res(imResponse)
    }).catch(err => {
      console.warn('getGroupMemberList error:', err);
      rej(err)
    })
  })
}

// 获取群组列表
export const groupList = chat => {
  return new Promise((res, rej) => {
    // 该接口默认只会拉取这些资料：群类型、群名称、群头像、最后一条消息的时间。
    let promise = chat.getGroupList();
    promise.then(function (imResponse) {
      const groupList = imResponse.data.groupList
      deBug && console.log('群组列表', imResponse.data.groupList); // 群组列表
      res(groupList)
    }).catch(function (imError) {
      console.warn('getGroupList error:', imError); // 获取群组列表失败的相关信息
      rej(imError)
    });
  })
}

// 获取单个群成员
export const getMember = (chat, groupID, userIDList) => {
  return new Promise((res, rej) => {
    let promise = chat.getGroupMemberProfile({
      groupID,
      userIDList, // 注意：即使只获取一个群成员的资料，也要用数组类型，例如：userIDList: ['user1']
    });
    promise.then((imResponse) => {
      const memberList = imResponse.data.memberList; // 群成员。
      res(memberList)
    }).catch((imError) => {
      console.warn('getGroupMemberProfile error:', imError);
      rej(imError)
    });

  })
}

// 获取全部人员
export const getAllMembers = (chat, groupID, offset = 0) => {
  return new Promise((res, rej) => {
    const options = {
      groupID,
      count: 15,
      offset,
    }
    const list = []
    chat.getGroupMemberList(options).then(async (imResponse) => {
      const memberList = imResponse.data.memberList; // 群成员。
      deBug && console.log('获取全部人员', options, memberList);
      list.push(...memberList)
      if (memberList.length == options.count) {
        offset += options.count
        const memberlist = await getAllMembers(chat, groupID, offset)
        list.push(...memberlist)
      }
      res(list)
    }).catch(err => {
      console.warn('getGroupMemberList error:', err);
      rej(err)
    })
  })
}

// 转换在线人员
export const transMembers = (list, memberObj, managerList) => {
  return new Promise((res, rej) => {
    try {
      const member = Object.assign({ length: 0, onlineLen: 0, }, memberObj)
      list.forEach(element => {
        const { userID } = element
        const isExistence = member[userID]
        // 排序
        let sort = 1
        let role = ''
        const roleList = managerList.sort(function (a, b) {
          return a.role - b.role;
        }).filter(v => v.id == userID)
        if (roleList.length == 0) {
          sort = 1
          role = 'Member'
        } else if (roleList[0].role == 1) {
          sort = 100
          role = 'Owner'
        } else if (roleList[0].role == 2) {
          sort = 90
          role = 'Admin'
        } else if (roleList[0].role == 3) {
          sort = 89
          role = 'Me'
        }
        member[userID] = Object.assign(element, {
          // 排序
          sort,
          // 角色
          role,
        })
        if (!isExistence) {
          member.length += 1
          member.onlineLen += 1
        }
      });
      deBug && console.log('在线人员', member, list);
      res(member)
    } catch (error) {
      rej(error)
    }
  })
}

// 获取所有历史消息
export const getHistoryMsgs = (chat, conversationID, nextReqMessageID) => {
  return new Promise((res, rej) => {
    let promise = chat.getMessageList({
      conversationID: 'GROUP' + conversationID,
      nextReqMessageID
    });
    let list = []
    promise.then(async (imResponse) => {
      const messageList = imResponse.data.messageList; // 消息列表。
      const nextID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
      const isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。
      list.push(...messageList)
      if (isCompleted) {
        // 如果已经拉完所有消息，则直接返回消息列表
        res(messageList);
      } else {
        // 如果还有剩余消息，则递归调用getHistoryMsgs，并将结果与当前的消息列表合并
        const nextList = await getHistoryMsgs(chat, conversationID, nextID);
        const combinedList = nextList.concat(messageList); // 合并当前消息列表和下一个消息列表
        deBug && console.log('积累的list', combinedList);
        res(combinedList);
      }
    }).catch(() => { rej() })
  })
}

// 禁言个人
export const muteMember = (chat, groupID, userID, muteTime) => {
  return new Promise((res, rej) => {
    let promise = chat.setGroupMemberMuteTime({
      groupID,
      userID,
      muteTime, // 设为0，则表示取消禁言
    });
    promise.then(function (imResponse) {
      deBug && console.log(imResponse.data.group); // 修改后的群资料
      deBug && console.log(imResponse.data.member); // 修改后的群成员资料
      const group = imResponse.data.group
      const member = imResponse.data.member
      res({
        group,
        member
      })
    }).catch(function (imError) {
      console.warn('setGroupMemberMuteTime error:', imError); // 禁言失败的相关信息
      rej(imError)
    });
  })
}

// 禁言整个群
export const muteGroup = (chat, groupID, muteAllMembers) => {
  return new Promise((res, rej) => {
    // 全体禁言
    let promise = chat.updateGroupProfile({
      groupID,
      muteAllMembers, // true 表示全体禁言，false表示取消全体禁言
    });
    promise.then(function (imResponse) {
      deBug && console.log('禁言整个群', imResponse.data.group) // 修改成功后的群组详细资料
      const group = imResponse.data.group
      res(group)
    }).catch(function (imError) {
      console.warn('updateGroupProfile error:', imError); // 修改群组资料失败的相关信息
      rej(imError)
    });
  })
}

// 踢出个人
export const deleteMember = (chat, groupID, userIDList, duration = 0, reason = '') => {
  return new Promise((res, rej) => {
    // 直播群踢出群成员
    let promise = chat.deleteGroupMember({
      groupID,
      userIDList,
      reason,
      duration,
    });
    promise.then(function (imResponse) {
      const group = imResponse.data.group
      const userIDList = imResponse.data.userIDList
      deBug && console.log('删除后的群组信息', imResponse.data.group); // 删除后的群组信息
      deBug && console.log('被删除的群成员的 userID 列表', imResponse.data.userIDList); // 被删除的群成员的 userID 列表
      res({ group, userIDList })
    }).catch(function (imError) {
      console.warn('deleteGroupMember error:', imError); // 错误信息
      rej(imError)
    });
  })
}

// 获取群资料
export const getGroupInfo = (chat, groupID) => {
  return new Promise((res, rej) => {
    let promise = chat.getGroupProfile({
      groupID,
    });
    promise.then(function (imResponse) {
      deBug && console.log('获取群资料', imResponse.data.group);
      const group = imResponse.data.group
      res(group)
    }).catch(function (imError) {
      console.warn('getGroupProfile error:', imError); // 获取群详细资料失败的相关信息
      rej(imError)
    });
  })
}

// 消息过滤 数组组
// { id: 0, name: '查看全部聊天内容' },
// { id: 1, name: '只看老师和助教' },
// { id: 2, name: '只看提问' },
// { id: 3, name: '只看自己' },
export const msgFilters = (msglist, selectId, managerList) => {
  let idList = []
  let list = []
  switch (selectId) {
    case 0:
      return msglist
    case 1:
      idList = managerList.map(v => v.id)
      msglist.forEach(v => {
        if (idList.indexOf(v.from) != -1) {
          list.push(v)
        }
      })
      return list
    case 2:
      idList = managerList.filter(v => v.role == 3).map(v => v.id)
      msglist.forEach(v => {
        if (idList.indexOf(v.from) != -1 || v.isQuestion) {
          list.push(v)
        }
      })
      return list
    case 3:
      idList = managerList.filter(v => v.role == 3).map(v => v.id)
      msglist.forEach(v => {
        if (idList.indexOf(v.from) != -1) {
          list.push(v)
        }
      })
      return list
  }
}
// 消息过滤 单个
// { id: 0, name: '查看全部聊天内容' },
// { id: 1, name: '只看老师和助教' },
// { id: 2, name: '只看提问' },
// { id: 3, name: '只看自己' },
export const msgFilter = (msg, selectId, managerList) => {
  let idList = []
  switch (selectId) {
    case 0:
      return msg
    case 1:
      idList = managerList.map(v => v.id)
      if (idList.indexOf(msg.from) != -1) return msg
      return false
    case 2:
      idList = managerList.filter(v => v.role == 3).map(v => v.id)
      if (idList.indexOf(msg.from) != -1 || msg.isQuestion) {
        return msg
      } else {
        return false
      }
    case 3:
      idList = managerList.filter(v => v.role == 3).map(v => v.id)
      if (idList.indexOf(msg.from) != -1) return msg
      return false
  }
}

// 公告移动速度
export const getAnimationSpeed = (length) => {
  let speed = ''
  if (length > 22 && length < 60) {
    speed = 24
  } else if (length >= 60 && length < 100) {
    speed = 28
  } else {
    speed = 40
  }
  return speed
}

// 替换成表情
export const textReplaceEmoji = (oldText) => {
  let tempText = ''
  const text = transCharacterEntities(oldText)
  const exp1 = /(\[[\u4e00-\u9fa5]+\])|./g
  const exp2 = /\[[\u4e00-\u9fa5]+\]/
  const matchRes = text.match(exp1)

  matchRes.forEach(item => {
    // 如果该item 是表情
    if (exp2.test(item)) {
      try {
        tempText += `<span style="${emoji[item].value
          }" class="vm emoji-item"></span>`
      } catch (e) {
        tempText += item
      }
    } else {
      tempText += item
    }
  })
  return tempText
}

// 转义html
export const transCharacterEntities = (text) => {
  return text
    ? text
      .replace(/&/gi, '&amp;')
      .replace(/</gi, '&lt;')
      .replace(/>/gi, '&gt;')
      .replace(/'/gi, "'")
      .replace(/"/gi, '"')
    : ''
}

// 转换消息
export const transMsg = (item, managerList) => {
  const { ID, nick, avatar, from, timeTnterval, type, payload: { text, data, description, imageInfoArray } } = item
  let cloudCustomData
  try {
    cloudCustomData = JSON.parse(item.cloudCustomData)
  } catch (e) {
    cloudCustomData = {}
  }
  // 角色
  let role
  const roleList = managerList.filter(v => v.id == item.from)
  if (roleList.length == 0) {
    role = 'Member'
  } else if (roleList[0].role == 1) {
    role = 'Owner'
  } else if (roleList[0].role == 2) {
    role = 'Admin'
  } else if (roleList[0].role == 3) {
    role = 'Self'
  }

  // 是否是礼物消息
  let giftMsg
  try {
    if (data === 'sendGift') {
      const { data: { msg } } = JSON.parse(description)
      giftMsg = msg
    }
  } catch (e) {
    giftMsg = undefined
  }

  // 是否是图片文件消息
  let imageMsg
  try {
    if (type === TencentCloudChat.TYPES.MSG_IMAGE) {
      const imageContent = imageInfoArray && imageInfoArray[0].url
      imageMsg = imageContent
    }
  } catch (e) {
    imageMsg = undefined
  }

  return {
    ID,
    from,
    type: data,
    time: item.time,
    timeTnterval,
    nick,
    name: cloudCustomData.name,
    isQuestion: cloudCustomData.isQuestion,
    avatar,
    giftText: giftMsg || '',
    imgText: imageMsg || '',
    role,
    text: text ? textReplaceEmoji(text) : '',
  }
}

// 分页加载数据
export const getCurrTypeMsgList = (allList, page, limit, currType, managerList) => {
  return new Promise((res, rej) => {
    try {
      const start = limit * (page - 1)
      const end = start + limit
      const list = allList.slice(start, end)
      const filterList = msgFilters(list, currType, managerList)
      page += 1
      res(filterList)
    } catch (error) {
      rej(error)
    }
  })
}

// im发送礼物消息
export const sendGiftMsg = (chat, to, payload) => {
  return new Promise((res, rej) => {
    let message = this.chat.createCustomMessage({
      to: 'GROUP' + to,
      conversationType: TencentCloudChat.TYPES.CONV_GROUP,
      payload,
    });
    // 3. 发送消息
    let promise = chat.sendMessage(message);
    promise.then(function (imResponse) {
      res(imResponse)
    }).catch(function (imError) {
      // 发送失败
      console.warn('sendMessage error:', imError);
      rej(imError)
    });
  })
}

// im发送文本消息
export const sendTextMsg = (chat, to, payload, cloudCustomData) => {
  return new Promise((res, rej) => {
    let message = chat.createTextMessage({
      to,
      payload,
      cloudCustomData,
      conversationType: TencentCloudChat.TYPES.CONV_GROUP,
    })
    chat.sendMessage(message).then(function (imResponse) {
      // 发送成功
      deBug && console.log(imResponse);
      res(imResponse)
    }).catch(function (imError) {
      // 发送失败
      console.warn('sendMessage error:', imError);
      rej(imError)
    });
  })
}

// im发送图片消息
export const sendImgMsg = (chat, to, payload, cloudCustomData, onProgress) => {
  return new Promise((res, rej) => {
    // Web 端发送图片消息示例1 - 传入 DOM 节点
    // 1. 创建消息实例，接口返回的实例可以上屏
    let message = chat.createImageMessage({
      to,
      conversationType: TencentCloudChat.TYPES.CONV_GROUP,
      payload,
      // 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到）
      cloudCustomData,
      onProgress,
    });

    // 2. 发送消息
    let promise = chat.sendMessage(message);
    promise.then(function (imResponse) {
      // 发送成功
      deBug && console.log(imResponse);
      res(imResponse)
    }).catch(function (imError) {
      // 发送失败
      console.warn('sendMessage error:', imError);
      rej(imError)
    });
  })
}

// im发送自定义消息
export const sendCustomMsg = (chat, to, payload) => {
  return new Promise((res, rej) => {
    // 2. 创建消息实例，接口返回的实例可以上屏
    let message = chat.createCustomMessage({
      to,
      conversationType: TencentCloudChat.TYPES.CONV_GROUP,
      payload,
    });
    // 3. 发送消息
    let promise = chat.sendMessage(message);
    promise.then(function (imResponse) {
      // 发送成功
      deBug && console.log(imResponse);
      res(imResponse)
    }).catch(function (imError) {
      // 发送失败
      console.warn('sendMessage error:', imError);
      rej(imError)
    });
  })
}

// rxjs模拟数据流
export const mockDataSource = subject => {
  // 模拟数据流，这里使用 interval 来模拟每秒接收到一条数据
  // deBug&&console.log('mockJson',mockJson);
  interval(2000).subscribe(() => {
    // 模拟接收到的数据，可以替换为实际接收到的数据
    mockJson.forEach(v => {
      subject.next(v); // 将接收到的数据添加到 Subject 中
    })
  });
}

// rxjs模拟im数据流
export const mockImSource = subject => {
  // 模拟数据流，这里使用 interval 来模拟每秒接收到一条数据
  interval(1000).subscribe(() => {
    // 模拟接收到的数据，可以替换为实际接收到的数据
    subject.push(mockJson2)
  });
}

// 订阅数据源
export const readImSource = (arr, fn) => {
  interval(1000)
    .pipe(
      map(i => arr[i]), // 从数组中取数据
      //   take(this.array.length) // 只取数组长度次数的数据
    )
    .subscribe(data => {
      deBug && console.log('Sending data:', data);
      fn(data)
    });
}

// im 数据队列
export const imQueue = (dataArray) => {
  // 使用 from 将数组数据源转换为 Observable
  const dataSource$ = from(dataArray);
  // 使用 bufferTime 缓存数据，并且每隔200毫秒处理一次缓存的数据
  dataSource$.pipe(
    bufferTime(2000)
  ).subscribe(bufferedData => {
    deBug && console.log('Buffered data:', bufferedData);
    // 在这里处理缓存的数据
  });
  // 使用 bufferTime 缓存数据，并且每隔10000毫秒处理一次缓存的数据
  this.receivedDataSubject.pipe(
    bufferTime(10000)
  ).subscribe(bufferedData => {
    deBug && console.log('Buffered data:', bufferedData);
    // 在这里处理缓存的数据
    this.bufferedData = bufferedData; // 将缓存的数据存储到组件的数据属性中
  });
}

