const result = {};
const getItem = (key, name, x, y) => ({ key, name, value: `background-position:${x}px ${y}px` });
const emoji1 = [
  '[哈哈]',
  '[龇牙]',
  '[破涕为笑]',
  '[笑歪]',
  '[憨笑]',
  '[尴尬]',
  '[害羞]',
  '[放电]',
  '[微笑]',
  '[吐舌头]',
  '[酷]',
  '[色眯眯]',
  '[爱你]',
  '[闪眼]',
  '[放光]',
  '[无语]',
  '[傲慢]',
  '[呵呵]',
  '[翻白眼]',
  '[坏笑]',
  '[难过]',
];
emoji1.forEach((item, index) => {
  result[item] = getItem(index, item, -(index % 7) * 25, -(index <= 6 ? 0 : (index > 13 ? 50 : 25) ))
});
const emoji2 = [
  '[委屈]',
  '[保密]',
  '[酱紫]',
  '[睡觉]',
  '[生气]',
  '[生病]',
  '[可怜]',
  '[邪恶]',
  '[吓你]',
  '[男医生]',
  '[女护士]',
  '[男学生]',
  '[女学生]',
  '[宇航员男]',
  '[宇航员女]',
  '[比心男]',
  '[比心女]',
  '[举手男]',
  '[举手女]',
  '[玫瑰]',
  '[鲜花]',
];
emoji2.forEach((item, index) => {
  result[item] = getItem(index, item, -(index % 7) * 25, -(index <= 6 ? 75 : (index > 13 ? 125 : 100) ))
});
const emoji3 = [
  '[仙人掌]',
  '[花朵]',
  '[西瓜]',
  '[啤酒]',
  '[火箭]',
  '[礼物]',
  '[雪人]',
];
emoji3.forEach((item, index) => {
  result[item] = getItem(index, item, -(index % 7) * 25, -150)
});
export default result;
