// im数据
export const mockJson = [
  {
    "ID": Math.random(),
    "conversationID": "GROUP@TGS#3ADFVOXNX",
    "conversationType": "GROUP",
    "time": 1705375648,
    "sequence": 3,
    "clientSequence": 45949,
    "random": 61766596,
    "priority": "Normal",
    "nick": "潘兴达",
    "avatar": "https://f.dingdingkaike.com.cn/teacher/img_mrtx%402x.png",
    "isPeerRead": false,
    "nameCard": "",
    "hasRiskContent": false,
    "_elements": [
      {
        "type": "TIMTextElem",
        "content": {
          "text": "111"
        }
      }
    ],
    "isPlaceMessage": 0,
    "isRevoked": false,
    "from": "ddkk_test_tx_im_1276",
    "to": "@TGS#3ADFVOXNX",
    "flow": "in",
    "isSystemMessage": false,
    "protocol": "JSON",
    "isResend": false,
    "isRead": false,
    "status": "success",
    "_onlineOnlyFlag": false,
    "_groupAtInfoList": [],
    "_relayFlag": false,
    "atUserList": [],
    "cloudCustomData": "{\"isManager\":false}",
    "isDeleted": false,
    "isModified": false,
    "_isExcludedFromUnreadCount": false,
    "_isExcludedFromLastMessage": false,
    "clientTime": 1705375648,
    "senderTinyID": "144115239109406320",
    "readReceiptInfo": {},
    "needReadReceipt": false,
    "version": 0,
    "isBroadcastMessage": false,
    "isSupportExtension": false,
    "revoker": "",
    "revokerInfo": {
      "userID": "",
      "nick": "",
      "avatar": ""
    },
    "revokeReason": "",
    "payload": {
      "text": "111"
    },
    "type": "TIMTextElem"
  }
]

export const mockJson2 = [
  {
    "ID": "144115198244471703-1705567857-338143137",
    "conversationID": "GROUPs_1237",
    "conversationType": "GROUP",
    "time": 1705567857,
    "sequence": 94,
    "clientSequence": 94,
    "random": 338143137,
    "priority": "Lowest",
    "nick": "silently",
    "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83ep9ocHFrGAiaF0MyU136nMwJ67Gs3puG7cYfrPia8kq8mh5WfibyrZgiaAZdnKNoq4Vgia49INFQ5UPYYg/132",
    "isPeerRead": false,
    "nameCard": "",
    "hasRiskContent": false,
    "_elements": [
      {
        "type": "TIMGroupTipElem",
        "content": {
          "operationType": 1,
          "operatorID": "ddkk_test_tx_im_1275",
          "userIDList": [
            "ddkk_test_tx_im_1275"
          ],
          "memberNum": 2,
          "memberCount": 2,
          "memberList": [
            {
              "userID": "ddkk_test_tx_im_1275",
              "role": 200,
              "nick": "silently",
              "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83ep9ocHFrGAiaF0MyU136nMwJ67Gs3puG7cYfrPia8kq8mh5WfibyrZgiaAZdnKNoq4Vgia49INFQ5UPYYg/132"
            }
          ],
          "groupProfile": {
            "from": "@TIM#SYSTEM",
            "to": "ddkk_test_tx_im_1276",
            "groupName": "s_1237",
            "groupID": "s_1237"
          }
        }
      }
    ],
    "isPlaceMessage": 0,
    "isRevoked": false,
    "from": "@TIM#SYSTEM",
    "to": "s_1237",
    "flow": "in",
    "isSystemMessage": false,
    "protocol": "JSON",
    "isResend": false,
    "isRead": false,
    "status": "success",
    "_onlineOnlyFlag": true,
    "_groupAtInfoList": [],
    "_relayFlag": false,
    "atUserList": [],
    "cloudCustomData": "",
    "isDeleted": false,
    "isModified": false,
    "_isExcludedFromUnreadCount": false,
    "_isExcludedFromLastMessage": false,
    "clientTime": 1705567857,
    "senderTinyID": "144115198244471703",
    "readReceiptInfo": {},
    "needReadReceipt": false,
    "version": 0,
    "isBroadcastMessage": false,
    "isSupportExtension": false,
    "revoker": "",
    "revokerInfo": {
      "userID": "",
      "nick": "",
      "avatar": ""
    },
    "revokeReason": "",
    "payload": {
      "operationType": 1,
      "operatorID": "ddkk_test_tx_im_1275",
      "userIDList": [
        "ddkk_test_tx_im_1275"
      ],
      "memberNum": 2,
      "memberCount": 2,
      "memberList": [
        {
          "userID": "ddkk_test_tx_im_1275",
          "role": 200,
          "nick": "silently",
          "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83ep9ocHFrGAiaF0MyU136nMwJ67Gs3puG7cYfrPia8kq8mh5WfibyrZgiaAZdnKNoq4Vgia49INFQ5UPYYg/132"
        }
      ],
      "groupProfile": {
        "from": "@TIM#SYSTEM",
        "to": "ddkk_test_tx_im_1276",
        "groupName": "s_1237",
        "groupID": "s_1237"
      }
    },
    "type": "TIMCustomElem"
  }
]
