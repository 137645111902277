<template>
  <div class="fixed chatRoom-contain">
    <!--预览图片-->
    <transition name="fade">
      <div
        class="fixed previewImg"
        v-if="previewImgSrc"
        @click.self="previewImgSrc = ''"
      >
        <img :src="previewImgSrc" alt="" />
      </div>
    </transition>
    <div v-if="txInfo.classOver == 1" class="progress">
      课程已结束，聊天室内容保留7天，7天后自动关闭并清除内容
    </div>
    <div v-if="uploadprogress" class="progress" style="text-align: center">
      上传中...{{ uploadprogress }}
    </div>
    <el-tabs
      v-model="currComponent"
      :stretch="true"
      @click.native="showEmoji = false"
    >
      <el-tab-pane label="讨论区" name="msg">
        <ul
          @scroll="scroll"
          ref="msgList"
          :class="['msg-list',ImError&&'ImRejoin']"
          :style="`padding-bottom:${msgListPaddingBottom}px`"
        >
          <li v-if="loadingAllHistory" class="loading"></li>
          <li class="item reconnection" v-if="ImError">
            <div class="reconnection-text">聊天室已断开，请点击重连</div>
            <div class="reconnection-btn" @click="rejoin">立即重连</div>
          </li>
          <li
            v-for="item in messageList"
            :key="item.ID"
            :class="{ 'my-msg-item': item.from === myInfo.userID }"
          >
            <div v-if="item.timeTnterval" class="msg-time">
              {{ (item.timeTnterval / 1000) | formatTimeStamp('MM月dd日 hh:mm') }}
            </div>
            <div
              class="msg-item notification"
              v-if="item.type === 'notification'"
            >
              {{ item.text }}
            </div>
            <div class="msg-item giftText" v-else-if="item.giftText">
              <div class="text">{{ item.nick }}{{ item.giftText }}</div>
            </div>
            <div
              v-else-if="item.text || item.imgText"
              class="msg-item"
              :class="{ 'self-msg': myInfo.userID == item.from }"
            >
              <div class="user-photo">
                <img :src="item.avatar" />
              </div>
              <div class="msg-content">
                <div class="msg-item-top">
                  <span class="vm msg-item-user-name">
                    {{ item.nick }}
                    <template v-if="item.name">
                      ({{ item.name }})
                    </template>
                  </span>
                  <i
                    v-if="item.role=='Admin'"
                    class="vm role-icon2"
                    :class="{ pcWx: isPcWx }"
                  ></i>
                  <i
                    v-else-if="item.role=='Owner'"
                    class="vm role-icon1"
                    :class="{ pcWx: isPcWx }"
                  ></i>
                  <i
                    v-if="item.isQuestion"
                    class="vm role-icon3"
                    :class="{ pcWx: isPcWx }"
                  ></i>
                </div>
                <div v-if="item.text" class="msg-bottom">
                  <div class="vm msg-item-text" v-html="item.text"></div>
                </div>
                <div
                  @click="previewImage(item.imgText)"
                  v-else-if="item.imgText"
                  class="msg-bottom img"
                >
                  <img :src="item.imgText" />
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!--聊天框--->
        <chatInput
          v-if="!roomDestoryed"
          :showEmoji="showEmoji"
          :isAllMute="isAllMute"
          @updateEmojiStatus="showEmoji = arguments[0]"
          @updateGroupMute="updateGroupMute"
          @sendMessage="sendMessage"
          @upload-course-img="uploadCourseImg"
        ></chatInput>
      </el-tab-pane>
      <el-tab-pane
        :label="`在线(${onLineLen|| 0})`"
        name="member"
      >
        <div class="msg-list" style="padding-bottom: 0; background: #fff">
          <member
            v-if="!roomDestoryed"
            :member="memberList"
            :type="0"
            @length="onLineLen = arguments[0]"
            @updateMemberMute="updateMemberMute"
          ></member>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="`已禁言(${muteLength || 0})`" name="mute">
        <div class="msg-list" style="padding-bottom: 0; background: #fff">
          <member
            v-if="!roomDestoryed"
            :member="memberList"
            :type="1"
            @length="muteLength = arguments[0]"
            @updateMemberMute="updateMemberMute"
          ></member>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="`已拉黑(${blackListLength || 0})`" name="blackList">
        <div class="msg-list" style="padding-bottom: 0; background: #fff">
          <member
            v-if="!roomDestoryed"
            :member="memberList"
            :type="2"
            @length="blackListLength = arguments[0]"
          ></member>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div v-if="roomDestoryed" class="empty">
      <img class="img" src="~@ass/img/1.3.6/icon_kcyjs.png" alt="" />
      <div class="prmopt">聊天室已自动关闭</div>
    </div>
  </div>
</template>
<script>
  import TencentCloudChat from '@tencentcloud/chat';
  import TIMUploadPlugin from 'tim-upload-plugin';
  //import TIMProfanityFilterPlugin from 'tim-profanity-filter-plugin';
  import { interval } from 'rxjs';
  import { map, take } from 'rxjs/operators';
  import chatInput from './chatInput'
  import member from './member'
  import {
    login,
    joinGroup,
    groupList,
    getMember,
    getAllMembers,
    transMembers,
    getHistoryMsgs,
    muteMember,
    muteGroup,
    deleteMember,
    getGroupInfo,
    msgFilter,
    // getAnimationSpeed,
    msgFilters,
    transMsg,
    //getCurrTypeMsgList,
    // sendGiftMsg,
    sendTextMsg,
    sendImgMsg,
    sendCustomMsg,
    // mockDataSource,
    // mockImSource,
    // readImSource,
    // imQueue,
  } from './utils/imUtils'

  export default {
    name: 'ChatRoom',

    components: {
      chatInput,
      member,
    },

    data() {
      return {
        deBug: true,
        // 腾讯im
        chat: null,
        ImError: false,
        txInfo: {},
        // 消息过滤
        currTypeMsg: 0,

        // 自己的信息
        myInfo: {},
        // 房间的信息 老师的信息
        roomInfo: {},

        // 是否是管理员
        isManager: false,

        // 历史消息没有了
        historyMsgEmpty: false,
        // 加载所有聊天消息中
        loadingAllHistory: true,
        // 单次加载聊天消息中
        getHistoryMsgLoading: true,
        // 是否显示加载所有聊天消息弹窗弹窗
        showGoToTeacheConfirm: true,

        // 聊天列表
        page: 1,
        limit: 10,
        newMsgNum: 0,
        historyEnd: 0,
        messageList: [],
        msgQueue: [],
        // 所有的聊天记录
        allMessage: [],
        getHitsoryCount: 0,

        // 禁止滚动
        scrollDisable: false,

        // 显示被踢出直播间弹框
        isKickOutLiveroom: false,

        // 显示课程信息弹层
        // isShowCourse: false,

        // 是否是助教(是：true,否:false)
        // isAssistantTeacher: true,
        link: {},

        // 成员管理
        memberList: {},

        // 上传文件进度
        uploadImgPercentage: 0,

        showEmoji: false,

        currComponent: 'msg',

        onLineLen: 0,
        muteLength: 0,
        kicoutLength: 0,
        blackListLength: 0,

        // 聊天室是否关闭
        roomDestoryed: false,

        uploadprogress: '',
        previewImgSrc: '',
      }
    },

    computed: {
      emptyText() {
        return !this.txInfo.chatroomId ? '暂无内容，请稍后刷新尝试~' : '暂无聊天内容'
      },
      // 是否全体禁言
      isAllMute() {
        return this.roomInfo.muteAllMembers
      },
      // 消息列表底部padding
      msgListPaddingBottom() {
        let pd = this.showEmoji ? 295 : 120
        return this.isAllMute ? pd + 20 : pd
      },
    },

    created() {
      this.getImInfo()
      document.title = '叮叮开课直播聊天室'
      document.body.style.overflow = 'hidden'
    },

    async destroyed() {
      document.body.style.overflow = 'auto'
      if (this.myInfo.role != "Owner") {
        await this.chat.quitGroup(this.txInfo.chatroomId)
        await this.chat.destroy()
      }
    },

    methods: {
      getImInfo() {
        this.$http({
          url: '/Assistant/chatRoom',
          data: this.$route.query,
          callback: ({ data }) => {
            this.txInfo = Object.assign(data, {
              SDKAppID: data.sdkAppID,
              photo: data.chatroomEnterCustom[0],
              remark: data.chatroomEnterCustom[1],
              register: data.chatroomEnterCustom[2],
              name: data.chatroomEnterCustom[3],
              // chatroomId:'@TGS#3ADFVOXNX',
            })
            // 初始化聊天室
            this.init()
          },
        })
      },
      // 初始化聊天室
      async init() {
        // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
        this.chat = TencentCloudChat.create(this.txInfo); // SDK 实例通常用 chat 表示
        // this.chat.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
        this.chat.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用
        // 注册腾讯云即时通信 IM 上传插件
        this.chat.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin });
        // 注册腾讯云即时通信 IM 本地审核插件
        //this.chat.registerPlugin({ 'tim-profanity-filter-plugin': TIMProfanityFilterPlugin });
        await login(this.chat, this.txInfo.userID, this.txInfo.userSig)
        this.bindEvent()
      },
      // 绑定监听事件
      bindEvent() {
        // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
        this.chat.on(TencentCloudChat.EVENT.SDK_READY, this.onReady)
        // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
        this.chat.on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, this.onReceived)
        // SDK 收到消息被撤回的通知
        this.chat.on(TencentCloudChat.EVENT.MESSAGE_REVOKED, this.onMessageRevoked)
        // 收到消息被更改
        this.chat.on(TencentCloudChat.EVENT.MESSAGE_MODIFIED, this.onMessageModified)
        // 收到 SDK 发生错误通知，可以获取错误码和错误信息
        this.chat.on(TencentCloudChat.EVENT.ERROR, this.onError)
        this.chat.on(TencentCloudChat.EVENT.SDK_NOT_READY, this.onError)
        this.chat.on(TencentCloudChat.EVENT.NET_STATE_CHANGE, this.onNetStateChange)
        // 收到被踢下线通知
        this.chat.on(TencentCloudChat.EVENT.KICKED_OUT, this.onError)
      },
      // 解绑监听事件
      offEvent() {
        // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
        this.chat.off(TencentCloudChat.EVENT.SDK_READY, this.onReady)
        // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
        this.chat.off(TencentCloudChat.EVENT.MESSAGE_RECEIVED, this.onReceived)
        // SDK 收到消息被撤回的通知
        this.chat.off(TencentCloudChat.EVENT.MESSAGE_REVOKED, this.onMessageRevoked)
        // 收到消息被更改
        this.chat.off(TencentCloudChat.EVENT.MESSAGE_MODIFIED, this.onMessageModified)
        // 收到 SDK 发生错误通知，可以获取错误码和错误信息
        this.chat.off(TencentCloudChat.EVENT.ERROR, this.onError)
        this.chat.off(TencentCloudChat.EVENT.SDK_NOT_READY, this.onError)
        this.chat.off(TencentCloudChat.EVENT.SDK_NOT_READY, this.onNetStateChange)
        // 收到被踢下线通知
        this.chat.off(TencentCloudChat.EVENT.KICKED_OUT, this.onError)
        // 群组变化
        this.chat.off(TencentCloudChat.EVENT.GROUP_LIST_UPDATED, this.onGroupListUpdate)
      },
      // 重连
      rejoin() {
        this.offEvent()
        // 初始化聊天室
        this.init()
        this.ImError = false
      },
      async onReady(event) {
        // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
        // event.name - TIM.EVENT.SDK_READY
        this.deBug && console.log('ready', event)
        const grouplist = await groupList(this.chat)
        this.deBug && console.log('所有群', grouplist)
        if (grouplist.indexOf(this.txInfo.chatroomId) == -1) {
          await joinGroup(this.chat, this.txInfo.chatroomId)
        }
        // 获取拉黑人员
        await this.getMemberListData()
        // 获取群人员
        this.getAllMember()
        // 获取历史记录
        this.loadingAllHistory = true
        this.allMessage = []
        this.newMsgNum = 0
        const msglist = await getHistoryMsgs(this.chat, this.txInfo.chatroomId)
        this.deBug && console.log('获取历史记录', msglist);
        msglist.forEach(item => {
          this.addTimeInterval(transMsg(item, this.txInfo.managerList))
        })

        this.deBug && console.log('获取allMessage历史数据', this.allMessage);
        //设置历史消息长度
        this.historyEnd = this.allMessage.length
        this.getHistoryMsgLoading = true
        await this.getLimitMsg()
        this.scrollListBottom()
        this.loadingAllHistory = false
        this.getHistoryMsgLoading = false

        // 获取单个人员信息
        const infoList = await getMember(this.chat, this.txInfo.chatroomId, [this.txInfo.userID])
        this.myInfo = infoList[0]
        this.deBug && console.log('获取单个人员信息', this.myInfo);
        // 获取群资料
        this.roomInfo = await getGroupInfo(this.chat, this.txInfo.chatroomId)

        // if(this.myInfo.role!="Owner"){
        //   // 发送进群消息
        //   const payload = {
        //     data:'enterRoom',
        //     description:JSON.stringify({...this.myInfo})
        //   }
        //   await sendCustomMsg(this.chat,this.txInfo.chatroomId,payload)
        // }

        // let list = []
        // mockImSource(list)
        // readImSource(list,item=>this.onReceived({data:item}))
      },
      onReceived(event) {
        // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
        this.deBug && console.log('收到的消息', event.data)
        this.msgQueue.push(...event.data)
        this.queueStart()
      },
      onMessageRevoked(event) {
        // 收到消息被撤回的通知
        this.deBug && console.log('收到消息被撤回的通知', event.data)
        const revokedKey = event.data.map(item => Number(item.sequence))
        this.messageList = this.messageList.filter(
          item => revokedKey.indexOf(Number(item.clientSequence)) == -1
        )
      },
      onMessageModified(event) {
        // 消息被更改
        this.deBug && console.log('消息被更改', event.data)
      },
      onError(event) {
        // 收到 SDK 发生错误通知，可以获取错误码和错误信息
        // event.name - TIM.EVENT.ERROR
        // event.data.code - 错误码
        // event.data.message - 错误信息
        this.deBug && console.log('收到 SDK 发生错误通知，可以获取错误码和错误信息', event)
        this.chat.destroy()
        this.ImError = true
      },
      // 网络变化
      onNetStateChange(event) {
        this.deBug && console.log('网络变化', event)
      },
      // 群组变化
      onGroupListUpdate(event) {
        this.deBug && console.log('群组变化', event)
      },

      start() {
        if (!this.txInfo.chatroomId) {
          this.roomDestoryed = true
        } else {
          this.init()
        }
      },

      // 拉黑名单
      getMemberListData() {
        return new Promise((res, rej) => {
          try {
            this.$http({
              name: 'blacklistInfo',
              data: this.$route.query,
              url: '/Assistant/blacklistInfo',
              callback: async ({ data: { blacklistInfo = [] } }) => {
                if (blacklistInfo) {
                  this.memberList = await transMembers(blacklistInfo, this.memberList, this.txInfo.managerList)
                }
                res()
              },
            })
          } catch (error) {
            rej(error)
          }
        })
      },

      // 获取所有管理员和老师在线人员
      async getAllMember() {
        const res = await getAllMembers(this.chat, this.txInfo.chatroomId)
        this.memberList = await transMembers(res, this.memberList, this.txInfo.managerList)
      },

      // 获取分页历史数据
      getLimitMsg() {
        return new Promise(async (res, rej) => {
          try {
            let filterList = [];
            let start = this.limit * (this.page - 1) + this.newMsgNum;
            let end = Math.min(start + this.limit, this.historyEnd);
            const list = this.allMessage.slice(start, end);
            this.deBug && console.log('获取limit历史数据', list)
            //const filteredMsgList = await getCurrTypeMsgList(list, this.page, this.limit, this.currTypeMsg, this.txInfo.managerList);
            const filteredMsgList = await msgFilters(list.reverse(), this.currTypeMsg, this.txInfo.managerList)
            filterList = filteredMsgList
            this.messageList = filterList.concat(this.messageList);
            if (end == this.historyEnd) {
              this.setMsgEmptyStatus();
            }
            res(filterList);
          } catch (error) {
            rej(error);
          }
        });
      },

      // 保存缓存数据,添加时间间隔
      addTimeInterval(msg, msgTimeInterval = 60000) {
        const newMsg = Object.assign({}, msg)
        const lastTime = this.allMessage[this.allMessage - 1]
        // // 如果添加和数据和最后一个数据 时间间隔差距有1分钟
        if (lastTime && newMsg.time - lastTime.time > msgTimeInterval) {
          newMsg.timeInterval = lastTime.time
        }
        this.allMessage.unshift(newMsg)
        return newMsg
      },

      // 设置消息为空状态
      setMsgEmptyStatus() {
        this.deBug && console.log('没有更多历史消息了');
        this.historyMsgEmpty = true
        if (this.currTypeMsg || this.messageList.length > 0) {
          this.messageList.unshift({
            ID: Math.random(),
            type: 'notification',
            text: '没有更多历史消息了',
          })
        }
      },

      // 到顶部加载数据
      async scroll({ target: { scrollTop } }) {
        if (
          scrollTop <= 10 &&
          !this.historyMsgEmpty &&
          !this.getHistoryMsgLoading
        ) {
          this.getHistoryMsgLoading = true
          this.page += 1
          let start = this.limit * (this.page - 1) + this.newMsgNum;
          this.deBug && console.log('到顶部加载数据', scrollTop, this.historyMsgEmpty, this.getHistoryMsgLoading, (start > this.historyEnd))
          if (start > this.historyEnd) return
          const scrollHeight = this.$refs.msgList.scrollHeight
          await this.getLimitMsg()
          this.positionToTop(scrollHeight)
          clearTimeout(this.historyTimes)
          this.historyTimes = setTimeout(() => {
            this.getHistoryMsgLoading = false
          }, 500)
        }
        //this.scrollDisable = offsetHeight + scrollTop + 5 < scrollHeight
      },

      // 聊天区定位置Top
      positionToTop(scrollHeight) {
        // 区分安卓的微信的情况
        if (this.isIPhone) {
          setTimeout(() => {
            const s = scrollHeight - 2.1 * this.$root.fontSize
            this.$refs.msgList.scrollTop = this.$refs.msgList.scrollHeight - s
          }, 300)
        } else {
          this.$refs.msgList.scrollTop = this.$refs.msgList.scrollHeight - scrollHeight
        }
      },

      // 滚动到列表最底部
      scrollListBottom(jump) {
        if (!jump && this.scrollDisable) return
        this.$refs.msgList.scrollTop = this.$refs.msgList.scrollHeight
      },

      delayScrollBottom() {
        clearTimeout(this.scrollListBottomTimes)
        const time = !this.isFirstLoad ? 2600 : 500
        this.scrollListBottomTimes = setTimeout(() => {
          this.scrollListBottom(!this.isFirstLoad)
          this.isFirstLoad = true
        }, time)
      },

      autoScrollListBottom() {
        const scrollHeight = document.getElementsByClassName('msg-list')[0].scrollHeight
        const offsetHeight = document.getElementsByClassName('msg-list')[0].offsetHeight
        const scrollTop = document.getElementsByClassName('msg-list')[0].scrollTop
        const isToBottom = (scrollHeight - offsetHeight - scrollTop) < 100
        this.deBug && console.log('自动滚动到底部', isToBottom)
        if (isToBottom) {
          this.$nextTick(() => {
            this.$refs.msgList.scrollTop = this.$refs.msgList.scrollHeight
          })
        }
      },

      queueStart() {
        if (this.msgQueueTimer) return
        this.msgQueueTimer = setTimeout(() => {
          const fn = async msg => {
            const { conversationID,conversationType,type, payload: { data, description } } = msg
            console.log('群消息',conversationType == "GROUP"&&conversationID!=(conversationType+this.txInfo.chatroomId));
            if(conversationType == "GROUP"&&conversationID!=(conversationType+this.txInfo.chatroomId)) return
            const sendText = Object.assign(msg, {
            })
            const currTypeMsg = this.currTypeMsg
            let copyRes
            let filterMsg
            let parseJson
            let options
            let isBlack
            let infoList
            this.deBug && console.log('消息类型', type);
            switch (type) {
              case TencentCloudChat.TYPES.MSG_GRP_TIP: // 通知消息
              case TencentCloudChat.TYPES.MSG_GRP_SYS_NOTICE: // 通知消息
                this.onTeamNotificationMsg(msg)
                break
              case TencentCloudChat.TYPES.MSG_TEXT: // 聊天消息
              case TencentCloudChat.TYPES.MSG_IMAGE: // 图片消息
                // 保存到缓存数据
                copyRes = this.addTimeInterval(transMsg(msg, this.txInfo.managerList))
                this.newMsgNum += 1
                filterMsg = msgFilter(copyRes, currTypeMsg, this.txInfo.managerList)
                if (filterMsg) {
                  this.messageList.push(filterMsg)
                  this.autoScrollListBottom()
                }
                break
              case TencentCloudChat.TYPES.MSG_CUSTOM: // 聊天消息
                switch (data) {
                  //case 'enterMember':
                  //  parseJson = JSON.parse(description)
                  //  options = Object.assign(sendText, {
                  //    text: `${parseJson.nick}进入了直播间`,
                  //  })
                  //  this.messageList.push(_.cloneDeep(options))
                  //  this.autoScrollListBottom()
                  //  infoList = await getMember(this.chat, this.txInfo.chatroomId, [parseJson.userID])
                  //  this.memberList = await transMembers(infoList, this.memberList, this.txInfo.managerList)
                  //  this.room_mood += 1
                  //  this.$emit('increasePopularity')
                  //  break;
                  //case 'exitMember':
                  //  parseJson = JSON.parse(description)
                  //  options = Object.assign(sendText, {
                  //    text: `${parseJson.nick}退出了直播间`,
                  //  })
                  //  this.messageList.push(_.cloneDeep(options))
                  //  this.autoScrollListBottom()
                  //  delete this.memberList[parseJson.userID]
                  //  this.memberList.length -= 1
                  //  this.memberList.onlineLen -= 1
                  //  break;
                  // 禁言
                  case 'muteMember':
                    parseJson = JSON.parse(description)
                    options = Object.assign(sendText, {
                      text: `${parseJson.userID == this.txInfo.userID ? '你' : parseJson.nick}${parseJson.muteUntil * 1000 > Date.now() ? '被禁言' : '被取消禁言'}`,
                      type: 'notification',
                    })
                    if (parseJson.muteUntil * 1000 > Date.now()) {
                      this.messageList.push(_.cloneDeep(options))
                      this.autoScrollListBottom()
                    }
                    if (parseJson.userID == this.txInfo.userID) {
                      // 获取自己信息
                      this.myInfo = parseJson
                    }
                    this.memberList = await transMembers([_.cloneDeep(parseJson)], this.memberList, this.txInfo.managerList)
                    this.deBug && console.log('个人 禁言/取消禁言', options, infoList);
                    break;
                  // 拉黑
                  case 'courseBlackList':
                    parseJson = JSON.parse(description)
                    // 2拉黑 1取消拉黑
                    isBlack = parseJson.blacklist == 2
                    // 获取单个人员信息
                    infoList = await getMember(this.chat, this.txInfo.chatroomId, [parseJson.userID])
                    infoList[0].disabled = isBlack
                    this.memberList = await transMembers(_.cloneDeep(infoList), this.memberList, this.txInfo.managerList)
                    options = Object.assign({}, sendText, {
                      text: `${infoList[0].nick}${isBlack ? '被拉黑' : '取消拉黑'}`,
                      type: 'notification',
                    })
                    this.deBug && console.log('拉黑', isBlack, parseJson)
                    if (isBlack) {
                      this.messageList.push(_.cloneDeep(options))
                    }
                    break;
                }
                break
              default:
            }
          }
          // 文本消息
          this.msgQueue.forEach(fn)
          // 优先级高的直接执行
          this.msgQueue = []
          this.msgQueueTimer = null
        }, 200)
      },

      // 收到聊天室系统消息
      async onTeamNotificationMsg(msg) {
        this.deBug && console.log('收到聊天室系统消息', msg)
        const { payload: { operatorID, operationType, newGroupProfile, userIDList, memberList } } = msg
        const sendText = Object.assign(msg, {
          type: 'notification',
        })
        //const eqSelfAccount = groupProfile.to === this.myInfo.userID
        let options = {}
        //let myInfoList
        let infoList
        switch (operationType) {
          // 群成员进群通知
          case 1:
            interval(300).pipe(
              map(i => memberList[i]), // 从数组中取数据
              take(memberList.length) // 只取数组长度次数的数据
            )
              .subscribe(item => {
                this.deBug && console.log('群成员进群通知', item);
                const { nick } = item
                options = Object.assign({}, sendText, {
                  text: `${nick}进入了直播间`,
                })
                this.messageList.push(_.cloneDeep(options))
                this.autoScrollListBottom()
              });
            infoList = await getMember(this.chat, this.txInfo.chatroomId, userIDList)
            this.memberList = await transMembers(infoList, this.memberList, this.txInfo.managerList)
            this.room_mood += 1
            this.$emit('increasePopularity')
            break;
          // 群成员退群通知
          case 2:
          case 3:
            if (operatorID != this.txInfo.userID) {
              interval(300).pipe(
                map(i => userIDList[i]), // 从数组中取数据
                take(userIDList.length) // 只取数组长度次数的数据
              )
                .subscribe(item => {
                  this.deBug && console.log('群成员退群通知', item);
                  //options = Object.assign({}, sendText, {
                  //  text: `${this.memberList[item].nick}退出了直播间`,
                  //})
                  //this.messageList.push(_.cloneDeep(options))
                  //this.autoScrollListBottom()
                  delete this.memberList[item]
                  this.memberList.length -= 1
                  this.memberList.onlineLen -= 1
                });
            }
            break;
          // 群 禁言/取消禁言
          case 6:
            // 获取群资料
            this.roomInfo = await getGroupInfo(this.chat, this.txInfo.chatroomId)
            options = Object.assign(sendText, {
              text: `${operatorID == this.myInfo.userID ? '你' : '管理员'}${newGroupProfile.muteAllMembers ? '开启了全体禁言' : '关闭了全体禁言'}`,
            })
            this.messageList.push(options)
            this.autoScrollListBottom()
            break;
          default:
        }
      },

      // 群 禁言/取消禁言
      async updateGroupMute() {
        const res = await muteGroup(this.chat, this.txInfo.chatroomId, !this.roomInfo.muteAllMembers)
        this.deBug && console.log('群 禁言/取消禁言', res);
        this.roomInfo = res
      },
      // 个人 禁言/取消禁言
      async updateMemberMute({ userID }) {
        const muteTime = this.memberList[userID].muteUntil * 1000 > Date.now() ? 0 : 24 * 60 * 60
        const res = await muteMember(this.chat, this.txInfo.chatroomId, userID, muteTime)
        const infoList = await getMember(this.chat, this.txInfo.chatroomId, [userID])
        this.memberList = await transMembers(infoList, this.memberList, this.txInfo.managerList)
        this.deBug && console.log('个人 禁言/取消禁言', res, infoList);
        const options = Object.assign(res.member, {
          type: 'notification',
          text: `${res.member.nick}${muteTime > 0 ? '被禁言' : '被取消禁言'}`,
        })
        if (muteTime) {
          this.messageList.push(_.cloneDeep(options))
          this.autoScrollListBottom()
        }
        const payload = {
          data: 'muteMember',
          description: JSON.stringify(res.member)
        }
        await sendCustomMsg(this.chat, this.txInfo.chatroomId, payload)
      },

      // 踢人
      async deleteMember({ userID }) {
        const duration = 1
        const res = await deleteMember(this.chat, this.txInfo.chatroomId, [userID], duration)
        delete this.memberList[userID]
        this.deBug && console.log('踢人', res);
      },

      // 踢出
      kickedCallback(message) {
        this.$confirm(
          message || '你被踢出了当前直播间，有疑问请联系机构老师或助教。',
          '温馨提示',
          {
            confirmButtonText: '确定',
          }
        )
      },

      // 发送聊天消息 object { value: String, isQuestion: Boolean }
      async sendMessage({ value, isQuestion }) {
        if (value) {
          let payload = {
            text: value,
          }
          let cloudCustomData = JSON.stringify({
            isQuestion,
            name: this.txInfo.remark
          })
          const res = await sendTextMsg(this.chat, this.txInfo.chatroomId, payload, cloudCustomData)
          // 保存到缓存数据
          const copyRes = this.addTimeInterval(transMsg(res.data.message, this.txInfo.managerList))
          this.newMsgNum += 1
          const filterMsg = msgFilter(copyRes, this.currTypeMsg, this.txInfo.managerList)
          if (filterMsg) {
            this.messageList.push(filterMsg)
            this.autoScrollListBottom()
          }
        }
      },

      // 上传课程图片
      async uploadCourseImg(e) {
        const node = e.target
        const file = e.target.files[0]
        if (!/image/i.test(file.type)) {
          this.$root.prompt('请上传图片类型文件')
        } else if (file.size > 1024 * 1024 * 7) {
          node.value = ''
          this.$root.prompt('图片大于7M')
        } else {
          const payload = {
            file,
          }
          let cloudCustomData = JSON.stringify({
            name: this.txInfo.remark
          })
          const res = await sendImgMsg(this.chat, this.txInfo.chatroomId, payload, cloudCustomData, (onProgress) => {
            this.uploadImgPercentage = onProgress
          })
          this.deBug && console.log('上传课程图片', res.data.message);
          this.uploadImgPercentage = 0
          // 保存到缓存数据
          const copyRes = this.addTimeInterval(transMsg(res.data.message, this.txInfo.managerList))
          this.newMsgNum += 1
          const filterMsg = msgFilter(copyRes, this.currTypeMsg, this.txInfo.managerList)
          if (filterMsg) {
            this.messageList.push(filterMsg)
          }
          setTimeout(() => {
            this.scrollListBottom(true)
          }, 500)
        }
      },

      // 预览图片
      previewImage(current) {
        this.previewImgSrc = current
      },
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .el-tabs,
::v-deep .el-tabs__content {
  height: 100%;
  .el-tab-pane {
    height: 100%;
    display: flex;
    flex-flow: column;
  }
  .el-tabs__item {
    font-size: 12px;
    padding: 0;
  }
  .el-tabs__header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 3;
    background: #fff;
    margin: 0;
    .el-tabs__nav-wrap::after {
      height: 1px;
      background: #e5e5e5;
    }
  }
}
.chatRoom-contain {
  z-index: 999;
  flex: 1;
  height: 100%;
  background: #f5f5f5;
  @keyframes marquee {
    100% {
      transform: translateX(-100%);
    }
  }
  .msg-list {
    flex: 1;
    position: relative;
    overflow-y: auto;
    padding: 59px 0 120px;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    &.ImRejoin {
      padding: 81px 0 120px;
    }
    .reconnection {
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      padding: 10px 20px;
      position: fixed;
      margin-top: 39px;
      background: #fff;
      top: 0px;
      width: 100%;
      z-index: 99;

      .reconnection-text,
      .reconnection-btn {
        font-size: 12px;
        line-height: 12px;
      }
      .reconnection-text {
        flex: 1;
        color: #fe2d46;
        padding: 5px 0px;
      }
      .reconnection-btn {
        color: #fff;
        padding: 5px 10px;
        background: #0aa29b;
        border-radius: 3px;
      }
    }
  }
  .live-room-prompt {
    background: #fff;
    width: 100%;
    position: relative;
    .marquee {
      position: relative;
      width: 100%;
      height: 1.6rem;
      color: #f39802;
      font-size: 0.55rem;
      line-height: 0.8rem;
      vertical-align: middle;
      padding: 0 0.7rem;
      white-space: nowrap;
      overflow: hidden;
      line-height: 1.6rem;
      div {
        position: absolute;
        height: 100%;
        top: 0;
        transform: translateX(19rem);
      }
      .transform-0 {
        transform: translateX(0rem);
      }
    }
    .marquee-width {
      width: 100%;
    }
    .marquee-width2 {
      width: 80%;
    }
    i {
      position: absolute;
      right: 0.7rem;
      top: 50%;
      transform: translateY(-50%);
      color: #f39802;
      font-size: 0.6rem;
      font-weight: bold;
      margin-left: 0.15rem;
    }
    .edit-btn {
      color: #0aa29b;
    }
  }
  /**聊天信息**/
  .msg-item {
    display: flex;
    position: relative;
    padding: 0.35rem 0.7rem;
    &.notification {
      color: #b3b2b3;
      display: block;
      font-size: 0.5rem;
      text-align: center;
    }
    &.giftText {
      font-size: 0;
      display: block;
      text-align: center;
      .text {
        font-size: 10px;
        font-weight: 500;
        padding: 4px 6px;
        border-radius: 2px;
        background: #ffe3e3;
        display: inline-block;
        color: rgba(255, 53, 53, 1);
      }
    }
    .user-photo {
      width: 1.4rem;
      height: 1.4rem;
      border-radius: 0.1rem;
      img {
        width: 100%;
        height: 100%;
        border-radius: inherit;
      }
    }
    .msg-content {
      flex: 1;
      margin-left: 0.5rem;
      .msg-item-top {
        font-size: 0;
        .msg-item-user-name {
          font-weight: 500;
          font-size: 0.55rem;
          line-height: 0.8rem;
          color: rgba(155, 155, 155, 1);
        }
        %roleIcon {
          width: 0.8rem;
          height: 0.55rem;
          margin: 0.15rem 0 0 0.15rem;
        }
        // 老师
        .role-icon1 {
          @extend %roleIcon;
          background: url('~@ass/img/1.3.6/ico_lsbs.svg') no-repeat center
            center;
          background-size: contain;
        }
        // 助教
        .role-icon2 {
          @extend %roleIcon;
          background: url('~@ass/img/1.3.6/ico_zjbs.svg') no-repeat center
            center;
          background-size: contain;
        }
        // 提问
        .role-icon3 {
          @extend %roleIcon;
          background: url('~@ass/img/1.3.6/ico_twbs.svg') no-repeat center
            center;
          background-size: contain;
        }
        .pcWx {
          width: 32px;
          height: 22px;
        }
      }
    }
  }
  .self-msg {
    .user-photo {
      order: 2;
    }
    .msg-content {
      text-align: right;
      margin: 0 0.5rem 0 0;
      .msg-bottom.img {
        display: inline-block;
      }
    }
  }
  .msg-bottom {
    font-size: 0;
    margin-top: 0.5rem;
    .msg-item-text {
      max-width: 71.46%;
      text-align: left;
      font-size: 0.65rem;
      font-weight: 500;
      line-height: 0.9rem;
      background-color: #fff;
      word-break: break-all;
      color: rgba(74, 74, 74, 1);
      padding: 0.4rem 0.5rem 0.5rem;
      border-radius: 0.2rem;
    }
    &.img {
      width: 134px;
      min-height: 40px;
      max-width: 134px;
      overflow-y: hidden;
      border-radius: 0.2rem;
      img {
        display: block;
        max-width: 100%;
        cursor: zoom-in;
        border-radius: 0.2rem;
      }
    }
  }
  /**如果是自己发送的聊天信息**/
  .my-msg-item {
    .msg-content {
      .msg-item-user-role {
        display: none;
      }
      .msg-bottom {
        .msg-item-text {
          color: #fff;
          background-color: #0aa29b;
        }
      }
    }
  }
  /**显示时间**/
  .msg-time {
    color: #b3b2b3;
    font-size: 0.5rem;
    font-weight: 500;
    line-height: 0.7rem;
    text-align: center;
    padding: 0.45rem 0 0.5rem;
  }
  /**loading**/
  .loading {
    width: 1.1rem;
    height: 1.1rem;
    font-size: 0;
    display: block;
    -webkit-animation: my-loading 1s steps(12, end) infinite;
    animation: my-loading 1s steps(12, end) infinite;
    background: transparent
      url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='120' height='120' viewBox='0 0 100 100'%3E%3Cpath fill='none' d='M0 0h100v100H0z'/%3E%3Crect width='7' height='20' x='46.5' y='40' fill='%23E9E9E9' rx='5' ry='5' transform='translate(0 -30)'/%3E%3Crect width='7' height='20' x='46.5' y='40' fill='%23989697' rx='5' ry='5' transform='rotate(30 105.98 65)'/%3E%3Crect width='7' height='20' x='46.5' y='40' fill='%239B999A' rx='5' ry='5' transform='rotate(60 75.98 65)'/%3E%3Crect width='7' height='20' x='46.5' y='40' fill='%23A3A1A2' rx='5' ry='5' transform='rotate(90 65 65)'/%3E%3Crect width='7' height='20' x='46.5' y='40' fill='%23ABA9AA' rx='5' ry='5' transform='rotate(120 58.66 65)'/%3E%3Crect width='7' height='20' x='46.5' y='40' fill='%23B2B2B2' rx='5' ry='5' transform='rotate(150 54.02 65)'/%3E%3Crect width='7' height='20' x='46.5' y='40' fill='%23BAB8B9' rx='5' ry='5' transform='rotate(180 50 65)'/%3E%3Crect width='7' height='20' x='46.5' y='40' fill='%23C2C0C1' rx='5' ry='5' transform='rotate(-150 45.98 65)'/%3E%3Crect width='7' height='20' x='46.5' y='40' fill='%23CBCBCB' rx='5' ry='5' transform='rotate(-120 41.34 65)'/%3E%3Crect width='7' height='20' x='46.5' y='40' fill='%23D2D2D2' rx='5' ry='5' transform='rotate(-90 35 65)'/%3E%3Crect width='7' height='20' x='46.5' y='40' fill='%23DADADA' rx='5' ry='5' transform='rotate(-60 24.02 65)'/%3E%3Crect width='7' height='20' x='46.5' y='40' fill='%23E2E2E2' rx='5' ry='5' transform='rotate(-30 -5.98 65)'/%3E%3C/svg%3E")
      no-repeat;
    background-size: 100%;
    position: static;
    margin: 0.5rem auto;
  }
  /**回放为空的处理**/
  .playBackMsgEmpty {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 90%;
    font-size: 0;
    text-align: center;
    .empty {
      display: inline-block;
      vertical-align: middle;
    }
  }
  /**加载所有数据**/
  .loading-all-msg {
    width: 100%;
    display: flex;
    height: 1.9rem;
    align-items: center;
    background-color: #ffe2e2;
    .t1 {
      flex: 1;
      padding: 0 0.7rem;
      font-weight: 500;
      font-size: 0.55rem;
      line-height: 1.9rem;
      color: rgba(255, 53, 53, 1);
    }
    .t2 {
      padding: 0 1rem;
      font-size: 0.55rem;
      font-weight: 400;
      line-height: 1.9rem;
      color: rgba(153, 153, 153, 1);
    }
  }
}
.empty {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);
  .img {
    display: block;
    margin: 0 auto;
  }
  .prmopt {
    font-size: 12px;
    color: #666666;
    line-height: 12px;
    margin-top: 20px;
    text-align: center;
  }
}
.progress {
  position: absolute;
  top: 40px;
  background: #ffeded;
  width: 100%;
  z-index: 6;
  font-size: 12px;
  color: #ff3535;
  line-height: 22px;
  padding-left: 20px;
}
.previewImg {
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  overflow: auto;
  img {
    max-width: 80%;
    margin: 10vh auto 0;
  }
}
</style>
<style>
/**显示表情**/
.emoji-item {
  width: 25px;
  height: 25px;
  background: url('https://img.dingdingkaike.com/minApp/emoji3x.png?imageslim')
    no-repeat;
  background-size: 175px;
}
</style>
